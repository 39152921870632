import { inject, Injectable, Type } from '@angular/core';
import {
    ConfirmationDialogComponent,
    IConfirmationDialog,
} from '@shared/components/confirmation-dialog';
import { CustomDialogHeaderComponent } from '@shared/components/custom-dialog/custom-dialog-header.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomDialogService {
    private dialogService: DialogService = inject(DialogService);
    private openDialogs: DynamicDialogRef[] = [];

    open<D, T = any>(component: Type<T>, config?: DynamicDialogConfig, data?: D) {
        const dialogRef = this.dialogService.open(component, {
            ...config,
            data: data,
            maskStyleClass: 'backdrop-blur-sm',
            modal: true,
            templates: {
                header: CustomDialogHeaderComponent,
            },
        });
        this.openDialogs.push(dialogRef);
        return dialogRef;
    }

    async confirm(config?: DynamicDialogConfig, data?: IConfirmationDialog) {
        return await lastValueFrom(
            this.open<IConfirmationDialog>(
                ConfirmationDialogComponent,
                { width: '350px', ...config },
                data,
            ).onClose,
        );
    }

    closeAll() {
        this.openDialogs.forEach(dialogRef => {
            dialogRef.close();
        });
        this.openDialogs = [];
    }
}
