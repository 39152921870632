import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { TPageType } from '@shared/interfaces/route.interface';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { AppService } from '@shared/services/app.service';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

export const UserGuard = (route: ActivatedRouteSnapshot, state: any) => {
    const appService: AppService = inject(AppService);
    const appRouteService: AppRouteService = inject(AppRouteService);
    const appNavigationService = inject(AppNavigationService);
    const user = inject(UserService).user;
    const currentPath = state.url.split('/')[1] as TPageType;

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            const activate: boolean = appRouteService.canActivate(
                currentPath.split('?')[0] as TPageType,
                state.url.split('?')[0],
                user(),
            );

            if (!activate && !environment.production) {
                console.log('Route cannot be activated', state.url);
                console.log('User role', user().role);
            }
            if (!activate) appNavigationService.navigateTo(appRouteService.defaultRoute);

            return true;
        }),
    );
};
