import { styles } from '@shared/services/app-styles.service';

const badge = {
    colorScheme: {
        light: {
            warn: {
                background: styles.warn,
                color: '#fff',
            },
        },
        dark: {
            warn: {
                background: styles.warn,
                color: '#fff',
            },
        },
    },
};

export default badge;
