import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppStorageService {
    setItem(storageName: string, item: any) {
        if (!item) {
            this.removeItem(storageName);
            return;
        }
        localStorage.setItem(storageName, JSON.stringify(item));
    }

    getItem(storageName: string) {
        const data = localStorage.getItem(storageName);
        if (!data || data == 'undefined') return undefined;
        return JSON.parse(<string>localStorage.getItem(storageName));
    }

    removeItem(storageName: string) {
        localStorage.removeItem(storageName);
    }
}
