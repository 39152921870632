import { styles } from '@shared/services/app-styles.service';

const listbox = {
    colorScheme: {
        light: {
            background: styles.layer,
        },
        dark: {
            background: styles.layer,
        },
    },
};

export default listbox;
