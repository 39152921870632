import { computed, inject, Injectable, Signal } from '@angular/core';
import { UsersHttpService } from '@core/http/users-http.service';
import { TranslateService } from '@ngx-translate/core';
import { createDataStore } from '@shared/data-store';
import { IResponseItem } from '@shared/interfaces/response-item.interface';
import { UserModel } from '@shared/models/user.model';
import { TUser } from '@shared/types/user.type';
import { translations } from '@utils/translations';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private usersHttpService = inject(UsersHttpService);
    private translateService = inject(TranslateService);

    store = createDataStore<UserModel, 'id'>({
        primaryKey: 'id',
        initialItemValue: new UserModel(),
    });

    item = computed(() => this.store.item());
    user = computed(() => this.item().value as UserModel);
    isLoggedIn: Signal<boolean> = computed(() => this.user().isLoggedIn);
    redirectToLanding: Signal<boolean> = computed(() => this.user().redirectToLanding);

    roles = computed<{ label: string; value: TUser }[]>(() => {
        return [
            { label: this.translateService.instant(translations.roles.Admin), value: 'Admin' },
            {
                label: this.translateService.instant(translations.roles.Technical),
                value: 'Technical',
            },
            {
                label: this.translateService.instant(translations.roles.Support),
                value: 'Support',
            },
            {
                label: this.translateService.instant(translations.roles.FacilityManager),
                value: 'FacilityManager',
            },
        ];
    });

    setUserData(): Promise<IResponseItem<UserModel>> {
        return new Promise<IResponseItem<UserModel>>(resolve => {
            this.usersHttpService.getUserData().subscribe({
                next: data => {
                    if (data.value) this.store.updateItem(data.value);

                    resolve(data);
                },
                error: () => {
                    resolve({});
                },
            });
        });
    }

    clearUser() {
        this.store.updateItem(new UserModel());
    }
}
