import { styles } from '@shared/services/app-styles.service';

const toast = {
    content: {
        padding: styles.paddingSm,
    },
    colorScheme: {
        light: {
            success: {
                background: styles.green,
                borderColor: styles.green,
                color: '#fff',
            },
            error: {
                background: styles.warn,
                borderColor: styles.warn,
                color: '#fff',
            },
            info: {
                background: styles.primary,
                borderColor: styles.primary,
                color: '#fff',
            },
        },
        dark: {
            success: {
                background: styles.green,
                borderColor: styles.green,
                color: '#fff',
            },
            error: {
                background: styles.warn,
                borderColor: styles.warn,
                color: '#fff',
            },
            info: {
                background: styles.primary,
                borderColor: styles.primary,
                color: '#fff',
            },
        },
    },
};

export default toast;
