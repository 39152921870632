import {
    booleanAttribute,
    Component,
    computed,
    HostBinding,
    inject,
    Input,
    input,
    numberAttribute,
    signal,
    Signal,
    WritableSignal,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { KONE, PRIMENG, TIcon, TIconType } from '@shared/components/icon/icon';
import { IconService } from '@shared/components/icon/icon.service';
import { TColors } from '@shared/interfaces/root-type.interface';
import { AppStyles } from '@shared/services/app-styles.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
    selector: 'icon',
    template: `
        @if (!loading()) {
            @switch (type()) {
                @case ('google') {
                    <i
                        class="material-icons-outlined"
                        [style]="{
                            color: colorFormatted(),
                            fontSize: fontSize(),
                        }">
                        {{ _name() }}
                    </i>
                }
                @case ('kone') {
                    <i
                        [innerHTML]="svg()"
                        [style]="{
                            '--color': colorFormatted(),
                            '--rotation': rotate() + 'deg',
                        }"></i>
                }
                @case ('primeng') {
                    <i
                        [class]="'pi ' + _name()"
                        [style]="{
                            color: colorFormatted(),
                            fontSize: fontSize(),
                            transform: 'rotate(' + rotate() + 'deg)',
                        }"></i>
                }
            }
        } @else {
            <p-skeleton shape="circle" [size]="fontSize()" />
        }
    `,
    styles: `
        ::ng-deep .icon svg {
            fill: var(--color);
            width: var(--size);
            height: var(--size);
            transform: rotate(var(--rotation));
            transition: all var(--transition-duration-200);
        }

        ::ng-deep .icon svg path {
            fill: var(--color) !important;
        }

        i {
            transition: all var(--transition-duration-200);
        }

        :host {
            display: flex;
            align-items: center;
            place-content: center;

            &.disable {
                span,
                i {
                    pointer-events: none;
                    opacity: 0.4;
                }
            }
        }
    `,
    imports: [SkeletonModule],
})
export class IconComponent {
    private appStyles: AppStyles = inject(AppStyles);
    private iconService: IconService = inject(IconService);

    @Input({ required: true })
    set name(name: TIcon) {
        this.setIcon(name);
    }

    color = input<TColors>();
    customColor = input<string>(''); // overwrites color
    rotateDeg = input<number, unknown>(0, { transform: numberAttribute });
    size = input<number, unknown>(0, { transform: numberAttribute });
    rotate = input<number, unknown>(0, { transform: numberAttribute });
    disabled = input<boolean, unknown>(false, { transform: booleanAttribute });
    loading = input<boolean, unknown>(false, { transform: booleanAttribute });

    type: Signal<TIconType> = computed(() => this.getType());
    colorFormatted: Signal<string> = computed(() => this.getColorFormatted());
    fontSize: Signal<string> = computed(() => this.getFontSize());

    _name: WritableSignal<TIcon> = signal('' as TIcon);
    svg: WritableSignal<SafeHtml> = signal('');

    @HostBinding('class')
    get class() {
        return 'icon';
    }

    @HostBinding('class.disable')
    get _disable() {
        return this.disabled();
    }

    @HostBinding('style.--size')
    get sizeVar() {
        return this.getFontSize();
    }

    private async setIcon(name: TIcon) {
        this._name.set(name);

        if (this.type() !== 'kone') return;

        const src = 'assets/icons/svg/' + name + '.svg';
        if (this.iconService.get(src)) {
            this.svg.set(this.iconService.get(src));
            return;
        }

        await this.iconService.loadIcon(src);
        if (this.iconService.get(src)) this.svg.set(this.iconService.get(src));
    }

    private getFontSize() {
        let size = this.size() ? this.size() : this.appStyles.getNumberFromPixels('iconSize');
        // KONE icon looks smaller than other icons - we decrease icon size to make it similar
        if (this.type() !== 'kone') size = size - 3;
        return size + 'px';
    }

    private getType(): TIconType {
        if (PRIMENG.includes(this._name() as any)) return 'primeng';
        else if (KONE.includes(this._name() as any)) return 'kone';
        else return 'google';
    }

    private getColorFormatted(): string {
        if (this.customColor()) return this.customColor();
        else if (this.color()) return this.appStyles.getColor(this.color()!);
        else return this.appStyles.getTextColor('text-color');
    }
}
