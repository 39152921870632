import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { styles } from '@shared/services/app-styles.service';
import _colorScheme from '@styles/primeng/_color-scheme';
import badge from '@styles/primeng/badge';
import breadcrumb from '@styles/primeng/breadcrumb';
import card from '@styles/primeng/card';
import checkbox from '@styles/primeng/checkbox';
import chip from '@styles/primeng/chip';
import datatable from '@styles/primeng/datatable';
import dialog from '@styles/primeng/dialog';
import image from '@styles/primeng/image';
import listbox from '@styles/primeng/listbox';
import panel from '@styles/primeng/panel';
import tag from '@styles/primeng/tag';
import toast from '@styles/primeng/toast';
import togglebutton from '@styles/primeng/togglebutton';
import tooltip from '@styles/primeng/tooltip';

const _primengConfig = definePreset(Aura, {
    components: {
        badge: badge,
        toast: toast,
        listbox: listbox,
        image: image,
        dialog: dialog,
        panel: panel,
        card: card,
        chip: chip,
        tooltip: tooltip,
        checkbox: checkbox,
        datatable: datatable,
        togglebutton: togglebutton,
        breadcrumb: breadcrumb,
        tag: tag,
    },
    semantic: {
        primary: {
            0: styles.primary,
            50: styles.primary50,
            100: styles.primary100,
            300: styles.primary300,
            400: styles.primary400,
            500: styles.primary500,
            600: styles.primary600,
            700: styles.primary700,
            800: styles.primary800,
            900: styles.primary900,
            950: styles.primary950,
        },
        red: {
            0: styles.warn,
            50: styles.warn50,
            100: styles.warn100,
            300: styles.warn300,
            400: styles.warn400,
            500: styles.warn500,
            600: styles.warn600,
            700: styles.warn700,
            800: styles.warn800,
            900: styles.warn900,
            950: styles.warn950,
        },
        green: {
            0: styles.green,
            50: styles.green50,
            100: styles.green100,
            300: styles.green300,
            400: styles.green400,
            500: styles.green500,
            600: styles.green600,
            700: styles.green700,
            800: styles.green800,
            900: styles.green900,
            950: styles.green950,
        },
        sky: {
            0: styles.primary,
            50: styles.primary50,
            100: styles.primary100,
            300: styles.primary300,
            400: styles.primary400,
            500: styles.primary500,
            600: styles.primary600,
            700: styles.primary700,
            800: styles.primary800,
            900: styles.primary900,
            950: styles.primary950,
        },
        colorScheme: _colorScheme,
    },
});

export default _primengConfig;
