import { Injectable } from '@angular/core';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

@Injectable({
    providedIn: 'root',
})
export class AwsAmplifyService {
    validateAuthSession() {
        return new Promise<string>(resolve => {
            fetchAuthSession()
                .then(session => {
                    const token = session.tokens?.accessToken.toString();
                    resolve(token ? token : '');
                })
                .catch(error => {
                    resolve('');
                });
        });
    }

    async signOut() {
        await signOut();
    }
}
