import {
    effect,
    inject,
    Injectable,
    RendererFactory2,
    signal,
    WritableSignal,
} from '@angular/core';
import { environment } from '@env/environment';
import { TIcon } from '@shared/components/icon';
import { AppStorageService } from '@shared/services/app-storage.service';
import { AppStyles } from '@shared/services/app-styles.service';
import _primengConfig from '@styles/primeng/_primeng-config';
import { PrimeNG } from 'primeng/config';

const STORAGE_NAME = environment.localStorageName + '_theme';

export type TTheme = 'light' | 'dark';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private renderer: RendererFactory2 = inject(RendererFactory2);
    private appStorage: AppStorageService = inject(AppStorageService);
    private appStyles: AppStyles = inject(AppStyles);
    private primeng = inject(PrimeNG);

    themes = signal<TTheme[]>(['dark', 'light']);
    theme: WritableSignal<TTheme> = signal('light');
    themeIcon: WritableSignal<TIcon> = signal('sun');

    constructor() {
        effect(() => {
            if (!this.theme()) return;

            this.appStorage.setItem(STORAGE_NAME, this.theme());
            if (this.theme()) {
                setTimeout(() => this.appStyles.set());
            }
        });

        this.primeng.theme.set({
            preset: _primengConfig,
            options: {
                darkModeSelector: '.dark',
                cssLayer: {
                    name: 'primeng',
                    order: 'tailwind-base, primeng, tailwind-utilities',
                },
            },
        });
    }

    fnInit() {
        const theme = this.appStorage.getItem(STORAGE_NAME) || this.theme();
        this.theme.set(theme);

        this.applyThemeToBodyApp();
        this.applyThemeToHtmlApp();
        this.applyThemeToMetaTag();
    }

    trigger(): void {
        if (this.theme() == 'dark') {
            this.theme.set('light');
            this.themeIcon.set('sun');
        } else {
            this.theme.set('dark');
            this.themeIcon.set('moon');
        }

        this.applyThemeToBodyApp();
        this.applyThemeToHtmlApp();
        this.applyThemeToMetaTag();
    }

    applyThemeToBodyApp(): void {
        const body: any = document.getElementById('body');
        const bodyClasses = body.classList;
        if (this.themes().length) bodyClasses.remove(...this.themes());

        body.classList.add(this.theme());
    }

    applyThemeToHtmlApp(): void {
        const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
        const htmlClasses = html.classList;
        if (this.themes().length) htmlClasses.remove(...this.themes());

        html.classList.add(this.theme());
    }

    applyThemeToMetaTag(): void {
        const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
        if (themeColorMetaTag) {
            themeColorMetaTag.setAttribute('content', this.appStyles.styles().layer);
        }
    }
}
