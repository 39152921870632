import { effect, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CustomDialogService } from '@shared/components/custom-dialog/custom-dialog.service';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AwsAmplifyService } from '@shared/services/aws-amplify.service';
import { UserService } from '@shared/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private customDialogService = inject(CustomDialogService);
    private awsAmplifyService = inject(AwsAmplifyService);
    private appNavigationService = inject(AppNavigationService);
    public userService = inject(UserService);

    private _loading: WritableSignal<boolean> = signal(true);
    private _loadingLogout: WritableSignal<boolean> = signal(false);
    private _error: WritableSignal<boolean> = signal(false);
    private _menuState: WritableSignal<boolean> = signal(true);

    loading: Signal<boolean> = this._loading.asReadonly();
    loadingLogout: Signal<boolean> = this._loadingLogout.asReadonly();
    error: Signal<boolean> = this._error.asReadonly();
    menuState: Signal<boolean> = this._menuState.asReadonly();

    constructor() {
        effect(() => {
            this.setImageBackground();
        });
    }

    startLoading(): void {
        this._loading.set(true);
    }

    stopLoading(): void {
        this._loading.set(false);
    }

    hasError(): void {
        this._error.set(true);
    }

    clearError(): void {
        this._error.set(false);
    }

    triggerMenu() {
        this._menuState.set(!this._menuState());
    }

    async logout() {
        this._loadingLogout.set(true);

        await new Promise(resolve => setTimeout(resolve, 450));

        await this.awsAmplifyService.signOut();
        this.customDialogService.closeAll();
        this.userService.clearUser();
        this.appNavigationService.navigateTo('auth');

        setTimeout(() => {
            this._loadingLogout.set(false);
        }, 200);
    }

    private setImageBackground(): void {
        if (
            (!this.userService.isLoggedIn() || !this.userService.user().hasRole) &&
            !this.loading()
        ) {
            this.bodyEl.classList.add('background-image');
        } else {
            this.bodyEl.classList.remove('background-image');
        }
    }

    get bodyEl(): HTMLElement {
        return document.getElementById('body') as HTMLElement;
    }
}
