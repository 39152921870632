import { NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'custom-dialog-header',
    template: `
        <div class="text-2xl font-bold">
            {{ dialogConfig.header || '' | translate }}
        </div>
        <div class="flex flex-grow justify-center items-center">
            @if (dialogConfig['headerMiddle']; as headerMiddle) {
                <ng-template [ngTemplateOutlet]="headerMiddle.template"></ng-template>
            }
        </div>
        @if (dialogConfig.closable) {
            <custom-button
                class="flex items-end justify-center"
                icon="close"
                type="grey"
                iconOnly
                (click)="ref.close()" />
        }
    `,
    styles: `
        :host {
            width: 100%;
            display: flex;
            align-items: center;
            place-content: center end;
        }
    `,

    imports: [CustomButtonComponent, TranslateModule, NgTemplateOutlet],
})
export class CustomDialogHeaderComponent {
    public dialogConfig: any = inject(DynamicDialogConfig);
    public ref: DynamicDialogRef = inject(DynamicDialogRef);
}
