import { styles } from '@shared/services/app-styles.service';

const chip = {
    gap: styles.gapItemXs,
    padding: {
        y: styles.padding1,
    },
    colorScheme: {
        light: {
            background: styles.layerSecondary,
            color: styles.textColor,
        },
        dark: {
            background: styles.layerSecondary,
            color: styles.textColor,
        },
    },
};

export default chip;
