import { styles } from '@shared/services/app-styles.service';

const card = {
    border: {
        radius: styles.borderRadius,
    },
    shadow: 'none',
};

export default card;
