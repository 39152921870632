import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, numberAttribute, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon';
import { TColors } from '@shared/interfaces/root-type.interface';
import { AppStyles } from '@shared/services/app-styles.service';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'custom-alert',
    template: `
        @if (visible()) {
            <div
                class="alert-balloon"
                [style.color]="appStyles.getColor(color())"
                @errorItemAnimation>
                @if (icon()) {
                    <icon [name]="icon()!" [color]="color()" class="mr-1.5" size="18" />
                }
                <div class="whitespace-pre-line text-start">{{ message() | translate }}</div>
                <ng-content></ng-content>
            </div>
        }
    `,
    styles: `
        :host {
            display: block;

            .alert-balloon {
                /*margin-block: var(--margin-sm);*/
                text-align: center;
                text-wrap: balance;
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
    `,
    animations: [
        trigger('errorItemAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    height: '0px',
                    minHeight: '0',
                    margin: '0',
                    padding: '0',
                }),
                animate(
                    '200ms',
                    style({
                        opacity: 1,
                        height: '*',
                        minHeight: '*',
                        margin: '*',
                        padding: '*',
                    }),
                ),
            ]),
            transition(':leave', [
                style({ opacity: 1, height: '*', minHeight: '*', margin: '*', padding: '*' }),
                animate(
                    '200ms',
                    style({
                        opacity: 0,
                        height: '0px',
                        minHeight: '0',
                        margin: '0',
                        padding: '0',
                    }),
                ),
            ]),
        ]),
    ],
    imports: [CommonModule, TranslateModule, IconComponent],
})
export class CustomAlertComponent {
    appStyles: AppStyles = inject(AppStyles);

    color = input.required<TColors>();
    message = input.required<string>();
    messagePlus = input<string>();
    icon = input<TIcon>();
    autoClose = input<number, unknown>(0, { transform: numberAttribute });

    alertClosed = output<void>();

    countdown$: Subject<void> = new Subject<void>();
    remainingSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    visible = computed(() => !!this.message());

    constructor() {
        effect(() => {
            if (this.visible() && this.autoClose() > 0) this.startTimer();
            else this.stopTimer();
        });
    }

    startTimer() {
        this.remainingSeconds$.next(this.autoClose());
        interval(1000)
            .pipe(takeUntil(this.countdown$))
            .subscribe(() => {
                this.remainingSeconds$.next(this.remainingSeconds$.getValue() - 1);

                if (this.remainingSeconds$.getValue() === 0) {
                    this.alertClosed.emit();
                    this.stopTimer();
                }
            });
    }

    stopTimer(): void {
        this.countdown$.next();
    }
}
