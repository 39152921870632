import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { KONE } from '@shared/components/icon/icon';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IconService {
    private domSanitizer: DomSanitizer = inject(DomSanitizer);
    private http: HttpClient = inject(HttpClient);

    private _svgIconsLoaded: WritableSignal<any> = signal({});

    async loadAll() {
        for (const icon of KONE) {
            await this.loadIcon('assets/icons/svg/' + icon + '.svg');
        }
    }

    async loadIcon(src: string) {
        const svg = await lastValueFrom(this.http.get(src, { responseType: 'text' }));
        this.add(src, this.domSanitizer.bypassSecurityTrustHtml(svg));
    }

    add(name: string, value: SafeHtml): void {
        this._svgIconsLoaded.update(icons => {
            return {
                ...icons,
                [name]: value,
            };
        });
    }

    get(name: string): string {
        return this._svgIconsLoaded()[name];
    }
}
