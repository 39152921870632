import { styles } from '@shared/services/app-styles.service';

const checkbox = {
    border: {
        radius: styles.borderRadiusCheckbox,
    },
    icon: {
        size: '0.8rem',
    },
};

export default checkbox;
