import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppService } from '@shared/services/app.service';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

export const AuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userData = inject(UserService);
    const appService: AppService = inject(AppService);
    const appNavigationService = inject(AppNavigationService);

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            if ((userData.isLoggedIn() || userData.redirectToLanding()) && userData.user().hasRole)
                return true;

            appNavigationService.navigateTo('auth');
            return false;
        }),
    );
};
