import { Directive, input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[header-middle]',
})
export class HeaderMiddleDirective {
    name = input.required<string>({ alias: 'header-middle' });

    constructor(public template: TemplateRef<any>) {}
}
