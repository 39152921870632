import { styles } from '@shared/services/app-styles.service';

const _common = {
    header: {
        cell: {
            padding: styles.paddingXs,
            color: styles.textColorSoft,
        },
    },
    body: {
        cell: {
            padding: styles.paddingXs,
        },
    },
    row: {
        striped: {
            background: styles.layerSecondary,
        },
    },
    column: {
        title: {
            fontWeight: styles.fontWeight,
        },
    },
};

const datatable = {
    colorScheme: {
        light: _common,
        dark: _common,
    },
};

export default datatable;
