import { styles } from '@shared/services/app-styles.service';

const tooltip = {
    colorScheme: {
        light: {
            background: styles.primary,
            color: '#fff',
        },
        dark: {
            background: styles.primary,
            color: '#fff',
        },
    },
};

export default tooltip;
