import { styles } from '@shared/services/app-styles.service';

const colorTheme = {
    content: {
        background: styles.layer,
        border: {
            color: styles.borderColor,
            radius: styles.borderRadius,
        },
        hover: {
            background: styles.hoverColor,
            color: styles.textColor,
        },
    },
    formField: {
        shadow: styles.boxShadowNone,
        background: styles.layer,
        border: {
            color: styles.borderColor,
            radius: styles.borderRadiusInput,
        },
        disabled: {
            background: styles.disabled,
            text: styles.textColorDisabled,
        },
    },
    list: {
        option: {
            border: {
                radius: styles.borderRadiusItem,
            },
        },
    },
    overlay: {
        select: {
            background: styles.layer,
            border: {
                radius: styles.borderRadiusItem,
                color: styles.borderColor,
            },
        },
        popover: {
            padding: styles.paddingMd,
            background: styles.layer,
            border: {
                color: styles.borderColor,
                radius: styles.borderRadius,
            },
        },
        modal: {
            padding: styles.paddingMd,
            background: styles.layer,
            border: {
                color: styles.borderColor,
                radius: styles.borderRadius,
            },
        },
    },
    text: {
        color: styles.textColor,
        mutedColor: styles.textColorSoft,
        hoverColor: styles.textColor,
    },
    highlight: {
        background: styles.primaryBackground,
        focusBackground: styles.hoverColor,
        color: styles.primary,
    },
    primary: {
        colorScheme: {
            light: {
                color: styles.primary,
                contrast: {
                    color: '#fff',
                },
            },
            dark: {
                color: styles.primary,
                contrast: {
                    color: '#fff',
                },
            },
        },
    },
};

const _colorScheme = {
    light: colorTheme,
    dark: colorTheme,
};

export default _colorScheme;
